<template>

  <div class="library__view">
    <h1 class="library__title">Components</h1>

    <!-- section buttons -->
    <section id="buttons" class="library__section">
      <p class="library__section-title">Buttons</p>

      <vb-button
          class="shadow-lg"
          :vb_options="{
            text: 'large info',
            variant: 'info',
            size: 'lg'
          }"
      />

      <span class="mr-3"></span>

      <vb-button
          :vb_options="{
            text: 'large disabled',
            size: 'lg',
            disabled: true,
            icon_disabled: 'lock'
          }"
      />

      <span class="mr-3"></span>

      <vb-button
          class="shadow"
          :vb_options="{
            text: 'default dark',
            variant: 'dark',
          }"
      />

      <span class="mr-3"></span>

      <vb-button
          class="shadow"
          :vb_options="{
            text: 'default icon',
            variant: 'info',
            icon: 'airplane'
          }"
      />

      <span class="mr-3"></span>

      <vb-button
          class="shadow-sm"
          :vb_options="{
            text: 'small light',
            variant: 'light',
            size: 'sm'
          }"
      />

      <span class="mr-3"></span>

      <vb-button
          class="shadow-sm"
          :vb_options="{
            text: 'small outline primary',
            variant: 'outline-primary',
            size: 'sm'
          }"
      />

    </section>

    <!-- section radio button -->
    <section id="radio" class="library__section">
      <p class="library__section-title">Radio</p>

      <div class="d-flex align-items-center">
        <vb-radio
            :vb_options="{
              text: 'Radio default',
              size: 'sm'
            }"
        />

        <span class="mr-3"></span>

        <vb-radio
            :vb_options="{
              text: 'Radio disabled',
              disabled:true
            }"
        />

        <span class="mr-3"></span>

        <vb-radio
            :vb_options="{
              text: 'Radio light default',
              type: 'light'
            }"
        />

        <span class="mr-3"></span>

        <vb-radio
            :vb_options="{
              text: 'Radio light disabled',
              type: 'light',
              disabled: true
            }"
        />

      </div>
    </section>

    <!-- section checkbox -->
    <section id="checkbox" class="library__section">
      <p class="library__section-title">Checkbox</p>

      <div class="d-flex align-items-center">
        <vb-checkbox
            :vb_options="{
              text: 'Checkbox default'
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Checkbox disabled',
              disabled:true
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Checkbox light default',
              type: 'light'
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Checkbox light disabled',
              type: 'light',
              disabled:true
            }"
        />
      </div>
    </section>

    <!-- section switch -->
    <section id="switch" class="library__section">
      <p class="library__section-title">Switch</p>

      <div class="d-flex align-items-center">
        <vb-checkbox
            :vb_options="{
              text: 'Switch default',
              switch: true
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Switch disabled',
              disabled: true,
              switch: true
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Switch light default',
              switch: true,
              type: 'light'
            }"
        />

        <span class="mr-3"></span>

        <vb-checkbox
            :vb_options="{
              text: 'Switch light disabled',
              disabled: true,
              switch: true,
              type: 'light'
            }"
        />

      </div>
    </section>

    <!-- section input -->
    <section id="input" class="library__section">
      <p class="library__section-title">Type Input</p>

        <div class="w-50">
          <vb-input
              :vb_options="{
                placeholder: 'Placeholder'
              }"
          />
        </div>

        <hr class="mt-4">

        <div class="w-50">
          <vb-input
              v-model="money"
              :vb_options="{
                  required: true,
                  name: 'Valore del buono',
                  price: true,
                  value: null,
                  placeholder: '1234',
                  formatter: format_price,
                }"/>
          <small class="mb-1"><i>Valore realmente gestito: </i><b>{{ money }}</b></small>
        </div>

    </section>

    <!-- section select -->
    <section id="select" class="library__section">
      <p class="library__section-title">Select</p>

      <div class="w-50">
        <b-form-group
            label-for="select"
            label="Label select"
            label-class="vb-label"
        >
          <vb-select :vb_options="{
                  options: [{ item: null, name: 'Please select an option', disabled: true }, { item: 'A', name: 'Option A' },{ item: 'B', name: 'Option B' },],
                  value_field: 'item',
                  text_field:'name',
                  value:null
                }" />
        </b-form-group>
      </div>

      <hr class="mt-4">

      <b-form inline class="vb-form">
        <b-form-group
            label-for="select"
            label="Label select inline"
            label-class="vb-label"
        >
          <vb-select :vb_options="{
                  options: [{ item: null, name: 'Please select an option', disabled: true }, { item: 'A', name: 'Option A' },{ item: 'B', name: 'Option B' },],
                  value_field: 'item',
                  text_field:'name',
                  value:null
                }" />
        </b-form-group>
      </b-form>
    </section>

    <!-- section breadcrumbs-->
    <section id="breadcrumbs" class="library__section">
      <p class="library__section-title">Breadcrumbs</p>

      <vb-breadcrumbs
          :vb_options="{
            items: [
              {
                text: 'Home',
                href: '#'
              },
              {
                text: 'Travel',
                href: '#'
              },
              {
                text: 'Paris',
                active: true
              }
            ]
          }"
      />
    </section>

    <!-- section tabs-->
    <section id="tabs" class="library__section">
      <p class="library__section-title">Tabs</p>

      <div class="w-50">
        <vb-tabs>
          <b-tab title="Tab 01">
            <p>I'm the first tab</p>
          </b-tab>
          <b-tab title="Tab 02">
            <p>I'm the second tab</p>
          </b-tab>
        </vb-tabs>
      </div>

    </section>

    <!-- section toggle -->
    <section id="toggle" class="library__section">
      <p class="library__section-title">Toggle</p>

      <div class="w-50">
        <vb-toggle
            :vb_options="{
              id: 'my-toggle',
              btn_text: 'Toggle Collapse',
            }"
        >
          <p>Hello World</p>
        </vb-toggle>
      </div>
    </section>

    <!-- section modal -->
    <section id="modal" class="library__section">
      <p class="library__section-title">Modal</p>

      <span class="text-info" v-b-modal="'modal_library'">open modal</span>

      <vb-modal :vb_options="{
          id: 'modal_library',
          size: 'lg',
          title: 'I pacchetti viaggio includono',
          hide_footer: true,
        }">
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laore et dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
        </p>
      </vb-modal>
    </section>

    <!-- section dropdown -->
    <section id="dropdown" class="library__section">
      <p class="library__section-title">Dropdown</p>

      <vb-dropdown :vb_options="{
                    size: 'lg',
                    text: 'large info'
                   }"
      >
        <b-dropdown-item>First Action</b-dropdown-item>
        <b-dropdown-item>Second Action</b-dropdown-item>
        <b-dropdown-item>Third Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else here...</b-dropdown-item>
        <b-dropdown-item active>Active action</b-dropdown-item>
        <b-dropdown-item disabled>Disabled action</b-dropdown-item>
      </vb-dropdown>

      <span class="mr-3"></span>

      <vb-dropdown :vb_options="{
                    variant: 'outline-primary',
                    text: 'default outline primary'
                   }"
      >
        <b-dropdown-item>First Action</b-dropdown-item>
        <b-dropdown-item>Second Action</b-dropdown-item>
        <b-dropdown-item>Third Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else here...</b-dropdown-item>
        <b-dropdown-item active>Active action</b-dropdown-item>
        <b-dropdown-item disabled>Disabled action</b-dropdown-item>
      </vb-dropdown>

    </section>

    <!-- section card default (vertical)  -->
    <section id="cards" class="library__section">
      <p class="library__section-title">Card Default (Vertical)</p>

      <b-row>
        <b-col cols="4">
          <vb-card
              :card="{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }"
          />
        </b-col>
        <b-col cols="4">
          <vb-card
              :card="{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }"
          />
        </b-col>
        <b-col cols="4">
          <vb-card
              :card="{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }"
          />
        </b-col>
      </b-row>

    </section>

    <!-- section card -->
    <section class="library__section">
      <p class="library__section-title">Card Horizontal</p>

      <div class="w-50">
        <vb-card
            variant="horizontal"
            :card="{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }"
        >
          <div class="mt-3">
            <a href="#">Slot content</a>
          </div>
        </vb-card>
      </div>

    </section>

    <!-- section image with text cta -->
<!--    <section class="library__section">
      <p class="library__section-title">Image with text cta</p>

      <div class="w-50">
        <image-with-text-cta
            :image_src="'assets/images/alpitour/statics/iStock-1180231917.jpg'"
            :text_heading="'Welfare non sufficiente?'"
            :text_content="'Se il tuo credito welfare non è sufficiente troviamo insieme una soluzione. <strong>Parliamo!</strong>'"
            :text_cta="[
              {
                cta_text: 'Chat',
                cta_icon: 'symbol_lb',
                cta_url: 'javascript:window.zE.activate()',
              },
              {
                cta_text: 'Whatsapp',
                cta_icon: 'whatsapp',
                cta_url: '#',
              },
              {
                cta_text: 'Email',
                cta_icon: 'mail',
                cta_url: 'mailto:',
              }
            ]"
        />
      </div>

    </section>-->

  </div>

</template>

<script>

export default {
  name: 'components',

  components: {
  },

  props: {},

  data() {
    return {
      money: 0,
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss">

</style>