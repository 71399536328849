var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"library__view"},[_c('h1',{staticClass:"library__title"},[_vm._v("Components")]),_c('section',{staticClass:"library__section",attrs:{"id":"buttons"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Buttons")]),_c('vb-button',{staticClass:"shadow-lg",attrs:{"vb_options":{
            text: 'large info',
            variant: 'info',
            size: 'lg'
          }}}),_c('span',{staticClass:"mr-3"}),_c('vb-button',{attrs:{"vb_options":{
            text: 'large disabled',
            size: 'lg',
            disabled: true,
            icon_disabled: 'lock'
          }}}),_c('span',{staticClass:"mr-3"}),_c('vb-button',{staticClass:"shadow",attrs:{"vb_options":{
            text: 'default dark',
            variant: 'dark',
          }}}),_c('span',{staticClass:"mr-3"}),_c('vb-button',{staticClass:"shadow",attrs:{"vb_options":{
            text: 'default icon',
            variant: 'info',
            icon: 'airplane'
          }}}),_c('span',{staticClass:"mr-3"}),_c('vb-button',{staticClass:"shadow-sm",attrs:{"vb_options":{
            text: 'small light',
            variant: 'light',
            size: 'sm'
          }}}),_c('span',{staticClass:"mr-3"}),_c('vb-button',{staticClass:"shadow-sm",attrs:{"vb_options":{
            text: 'small outline primary',
            variant: 'outline-primary',
            size: 'sm'
          }}})],1),_c('section',{staticClass:"library__section",attrs:{"id":"radio"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Radio")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('vb-radio',{attrs:{"vb_options":{
              text: 'Radio default',
              size: 'sm'
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-radio',{attrs:{"vb_options":{
              text: 'Radio disabled',
              disabled:true
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-radio',{attrs:{"vb_options":{
              text: 'Radio light default',
              type: 'light'
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-radio',{attrs:{"vb_options":{
              text: 'Radio light disabled',
              type: 'light',
              disabled: true
            }}})],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"checkbox"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Checkbox")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Checkbox default'
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Checkbox disabled',
              disabled:true
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Checkbox light default',
              type: 'light'
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Checkbox light disabled',
              type: 'light',
              disabled:true
            }}})],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"switch"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Switch")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Switch default',
              switch: true
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Switch disabled',
              disabled: true,
              switch: true
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Switch light default',
              switch: true,
              type: 'light'
            }}}),_c('span',{staticClass:"mr-3"}),_c('vb-checkbox',{attrs:{"vb_options":{
              text: 'Switch light disabled',
              disabled: true,
              switch: true,
              type: 'light'
            }}})],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"input"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Type Input")]),_c('div',{staticClass:"w-50"},[_c('vb-input',{attrs:{"vb_options":{
                placeholder: 'Placeholder'
              }}})],1),_c('hr',{staticClass:"mt-4"}),_c('div',{staticClass:"w-50"},[_c('vb-input',{attrs:{"vb_options":{
                  required: true,
                  name: 'Valore del buono',
                  price: true,
                  value: null,
                  placeholder: '1234',
                  formatter: _vm.format_price,
                }},model:{value:(_vm.money),callback:function ($$v) {_vm.money=$$v},expression:"money"}}),_c('small',{staticClass:"mb-1"},[_c('i',[_vm._v("Valore realmente gestito: ")]),_c('b',[_vm._v(_vm._s(_vm.money))])])],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"select"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Select")]),_c('div',{staticClass:"w-50"},[_c('b-form-group',{attrs:{"label-for":"select","label":"Label select","label-class":"vb-label"}},[_c('vb-select',{attrs:{"vb_options":{
                  options: [{ item: null, name: 'Please select an option', disabled: true }, { item: 'A', name: 'Option A' },{ item: 'B', name: 'Option B' },],
                  value_field: 'item',
                  text_field:'name',
                  value:null
                }}})],1)],1),_c('hr',{staticClass:"mt-4"}),_c('b-form',{staticClass:"vb-form",attrs:{"inline":""}},[_c('b-form-group',{attrs:{"label-for":"select","label":"Label select inline","label-class":"vb-label"}},[_c('vb-select',{attrs:{"vb_options":{
                  options: [{ item: null, name: 'Please select an option', disabled: true }, { item: 'A', name: 'Option A' },{ item: 'B', name: 'Option B' },],
                  value_field: 'item',
                  text_field:'name',
                  value:null
                }}})],1)],1)],1),_c('section',{staticClass:"library__section",attrs:{"id":"breadcrumbs"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Breadcrumbs")]),_c('vb-breadcrumbs',{attrs:{"vb_options":{
            items: [
              {
                text: 'Home',
                href: '#'
              },
              {
                text: 'Travel',
                href: '#'
              },
              {
                text: 'Paris',
                active: true
              }
            ]
          }}})],1),_c('section',{staticClass:"library__section",attrs:{"id":"tabs"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Tabs")]),_c('div',{staticClass:"w-50"},[_c('vb-tabs',[_c('b-tab',{attrs:{"title":"Tab 01"}},[_c('p',[_vm._v("I'm the first tab")])]),_c('b-tab',{attrs:{"title":"Tab 02"}},[_c('p',[_vm._v("I'm the second tab")])])],1)],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"toggle"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Toggle")]),_c('div',{staticClass:"w-50"},[_c('vb-toggle',{attrs:{"vb_options":{
              id: 'my-toggle',
              btn_text: 'Toggle Collapse',
            }}},[_c('p',[_vm._v("Hello World")])])],1)]),_c('section',{staticClass:"library__section",attrs:{"id":"modal"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Modal")]),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_library'),expression:"'modal_library'"}],staticClass:"text-info"},[_vm._v("open modal")]),_c('vb-modal',{attrs:{"vb_options":{
          id: 'modal_library',
          size: 'lg',
          title: 'I pacchetti viaggio includono',
          hide_footer: true,
        }}},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laore et dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. ")])])],1),_c('section',{staticClass:"library__section",attrs:{"id":"dropdown"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Dropdown")]),_c('vb-dropdown',{attrs:{"vb_options":{
                    size: 'lg',
                    text: 'large info'
                   }}},[_c('b-dropdown-item',[_vm._v("First Action")]),_c('b-dropdown-item',[_vm._v("Second Action")]),_c('b-dropdown-item',[_vm._v("Third Action")]),_c('b-dropdown-divider'),_c('b-dropdown-item',[_vm._v("Something else here...")]),_c('b-dropdown-item',{attrs:{"active":""}},[_vm._v("Active action")]),_c('b-dropdown-item',{attrs:{"disabled":""}},[_vm._v("Disabled action")])],1),_c('span',{staticClass:"mr-3"}),_c('vb-dropdown',{attrs:{"vb_options":{
                    variant: 'outline-primary',
                    text: 'default outline primary'
                   }}},[_c('b-dropdown-item',[_vm._v("First Action")]),_c('b-dropdown-item',[_vm._v("Second Action")]),_c('b-dropdown-item',[_vm._v("Third Action")]),_c('b-dropdown-divider'),_c('b-dropdown-item',[_vm._v("Something else here...")]),_c('b-dropdown-item',{attrs:{"active":""}},[_vm._v("Active action")]),_c('b-dropdown-item',{attrs:{"disabled":""}},[_vm._v("Disabled action")])],1)],1),_c('section',{staticClass:"library__section",attrs:{"id":"cards"}},[_c('p',{staticClass:"library__section-title"},[_vm._v("Card Default (Vertical)")]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('vb-card',{attrs:{"card":{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('vb-card',{attrs:{"card":{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('vb-card',{attrs:{"card":{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }}})],1)],1)],1),_c('section',{staticClass:"library__section"},[_c('p',{staticClass:"library__section-title"},[_vm._v("Card Horizontal")]),_c('div',{staticClass:"w-50"},[_c('vb-card',{attrs:{"variant":"horizontal","card":{
                  title: 'Title',
                  subtitle: 'SubTitle',
                  text: 'Sed posuere consectetur est at lobor-tis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
                  image: '/assets/images/alpitour/statics/iStock-1208856002.jpg',
                }}},[_c('div',{staticClass:"mt-3"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Slot content")])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }